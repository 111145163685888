.kusari {
  color: #eee;
}

.kusari-container {
  color: #eee;
  background-color: #000;
}

.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
}

.mb30 {
  margin-bottom: 30px;
}
.mr-5 {
  margin-right: 5px;
}

a {
  color: #e6007a;
}

a:hover {
  color: #e6007a;
}

.input-container {
  text-align: center;
  padding: 10px;
}

.kusari-input {
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  color: #eee;
}
input[class*="kusari-input"] {
  height: 45px;
}
textarea[class*="kusari-input"] {
  height: 112px;
}
.kusari-button {
  background: transparent;
  color: #eee;
  border: 1px solid #e6007a;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.kusari-input:focus {
  border: 1px solid #e6007a;
}

.title1 {
  text-align: center;
  position: relative;
  padding-bottom: 15px;
  text-rendering: optimizeLegibility;
  margin-bottom: 120px;
}
.title1::before {
  position: absolute;
  content: '';
  background: #e6007a;
  bottom: -2px;
  height: 1px;
  width: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.service-item {
  background-color: #000;
}

.services-item {
  font-size: 11px;
  padding: 10px;
  text-align: center;
}

.services-item h2 {
  font-size: 18px;
  font-weight: bold;
  padding-top: 50px;
  margin-bottom: 14px;
}

.card {
  border: 0;
}
.card:hover {
  border: 0;
}
.services-item:hover {
  border: 1px solid #e6007a;
}
.services-item:hover .services-icon {
  opacity: 1;
}
.services-item:hover .services-icon {
  background-color: #e6007a;
  border-color: #e6007a;
  color: #000;
}
.services-icon {
  position: absolute;
  top: -35px;
  height: 70px;
  width: 70px;
  font-size: 26px;
  color: #eee;
  text-align: center;
  z-index: 11;
  margin-bottom: 15px;
  line-height: 70px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 50%;
  margin-left: -35px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.services-icon i {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.services-desc {
  padding-left: 40px;
  padding-right: 40px;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.main-header {
  height: 100vh;
}
.kusari-bg {

  background-image: url("/src/images/bg-blur.jpg");
  /* filter: blur(5px);
  -webkit-filter: blur(5px);*/

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  z-index: -1;
}

.header-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.kusari-header {
  position: absolute !important;
  max-width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
}

.kusari-header .logo {
  line-height: 80px;
  background: rgba(255,255,255,.1);
  padding: 0 20px;
  float: left;
}

.kusari-header .logo a {
  color: white;
  text-decoration: none;
}

.kusari-header .socials {
  float: right;
  height: 80px;
  line-height: 80px;
}

.kusari-header .socials a {
  display: inline-block;
  color: #fff;
  font-size: 24px;
  margin-left: 10px;
}

.uk-container-center {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1220px) {
  .uk-container {
    max-width: 1200px;
    padding: 0 35px;
  }
}

.uk-container {
  box-sizing: border-box;
  max-width: 980px;
  padding: 0 25px;
}

.down-arrow {
  position: absolute;
  bottom: 70px;
  left: 50%;
  margin-left: -12px;
  -webkit-animation-name: uk-fade-top;
  -webkit-animation-duration: 1.6s;
  -webkit-animation-iteration-count: infinite;
  animation-name: uk-fade-top;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
}
.down-arrow i {
  color: #fff;
}